export default {
  home: {
    Weintek_Product_Verification: '威纶通产品授权验证',
    Sticker_SN: '贴纸 SN',
    Please_enter_the_SN_from_the_sticker: '请输入贴纸上记载的SN',
    Verification_Code: '验证码',
    Please_scratch_off_msg: '刮开银漆，取得验证码',
    Verify: '点击验证',
    Language: '语言',
    Please_wait_msg: '请稍等{qty}秒',
    You_are_not_human: '你不是人类',
    Serial_number_error: '请输入9个字符的SN',
    Serial_number_length_left: '还需要填写{qty}个字符',
    Serial_number_length_error: 'SN长度为9个字符'
  },
  first: {
    Weintek_Product_Verification: '威纶通产品授权验证',
    Result_Verified: '验证结果：正版授权',
    This_is_a_first_time_verification_msg: '此为首次验证，感谢您购买威纶通的人机界面产品。'
  },
  notFirst: {
    Weintek_Product_Verification: '威纶通产品授权验证',
    Result_Verified: '验证结果：正版授权',
    This_is_not_a_first_time_verification_msg: '非首次验证，感谢您购买威纶通的人机界面产品。',
    Date_of_first_verification: '首次验证时间',
    Our_record_indicates_this_verification_msg: '你查询的授权已被查询过，如果该记录非您查询，此授权码可能已经被抄袭，有关产品亦有赝品之疑，如有疑问欢迎联络威纶通。'
  },
  fail: {
    Weintek_Product_Verification: '威纶通产品授权验证',
    Result_Failed: '验证结果：验证失败，或为非法授权',
    The_verification_code_you_entered_is_invalid_msg: '请检查您输入的内容，内容一致，您所查询的授权则非正确授权码，有赝品之疑，如有疑问欢迎联络威纶通。'
  }
}
