import { createI18n } from 'vue-i18n'
import zhCN from './zh-cn'
import en from './en-us'

const i18n = createI18n({
  // 如果localStorage有沒有值，如果沒有就取瀏覽器
  locale: localStorage.getItem('locale') || navigator.language.slice(0, 2) === 'zh' ? (navigator.language === 'zh-TW' ? 'en-US' : 'zh-CN') : 'en-US',
  fallbackLocale: 'en-US',
  messages: {
    'zh-CN': zhCN,
    'en-US': en
  }
})
export default i18n
