import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import App from './App.vue'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import dayjs from 'dayjs'
import vueI18n from './i18n'

const app = createApp(App)

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
app.config.globalProperties.$dayjs = dayjs
app.use(VueAxios, axios)
app.use(router)
app.use(VueReCaptcha, {
  siteKey: '6LebRuUfAAAAACxhN3vvoXkYonTt3dCm63a2fpBn',
  loaderOptions: {
    useRecaptchaNet: true
  }
})
app.use(vueI18n)
app.component('LoadingOverlay', Loading)
app.mount('#app')
